import React, { useEffect, useState, useLayoutEffect } from "react"
import Layout from "../retech/components/layout/Layout"


import { Helmet } from "react-helmet"

import { Link } from "gatsby"
import { Dropdown, DropdownButton } from "react-bootstrap"

var showdown = require("showdown")
var converter = new showdown.Converter()
export default function SubProductDetailPage({ data, classes, pageContext }) {
  function makeHtml(txt) {
    return converter.makeHtml(txt)
  }
  const url = typeof window !== "undefined" ? window.location.href : ""
  const urlimage = typeof window !== "undefined" ? window.location.hostname : ""
  useEffect(() => {
    setFormState({
      ...formState,
      source: document.querySelector(".sourcep")?.innerText,
    })
  }, [])


  useLayoutEffect(() => {
    try {
      var text = Description;

      var mdConverter = new showdown.Converter();
      mdConverter.setOption('tables', true);
      var html = mdConverter.makeHtml(text);
      document.getElementById("descviewer").innerHTML = html;
      var elements = document.getElementById("descviewer").querySelectorAll("table");
      Array.from(elements).forEach(function (element) {
        element.classList.add("table", "table-bordered", "table-sm");
      });
    } catch (error) {

    }
  }, [])

  const [formState, setFormState] = useState({
    source: "",
    firstname: "",
    lastname: "",
    phone: "",
    email: "",
    priority: "",
    message: "",
  })

  const recaptchaRef = React.createRef()
  const list = pageContext.list;
  const DetailTitle = pageContext.DetailTitle;
  const cTALinkParent = pageContext.CTALinkParent;
  const {
    Title,
    CTALink,
    Description,
    Image,
    SubTitle,
    file,
    FileText,
    FileImage,
    RelatedProductShowLink,
    ImageDescriptionBody,
    ImageDescriptionShow
  } = pageContext.relatedProduct

  return (
    <Layout>
      <Helmet>
        <title>Retech | {Title} </title>

        <meta name="description" content={Description} />

        <meta property="og:type" content="website" />
        <meta property="og:url" content={url} />
        <meta property="og:title" content={Title} />
        <meta property="og:description" content={Description} />
        <meta property="og:image" content={urlimage + "/logo.svg"} />
        <meta property="twitter:card" content="summary_large_image" />
        <meta property="twitter:url" content={url} />
        <meta property="twitter:title" content={Title} />
        <meta property="twitter:description" content={Description} />
        <meta property="twitter:image" content={urlimage + "/logo.svg"} />
      </Helmet>
      <div className="container-fluid px-0 mb-5">
        <div
          className="show_bg_desktop d-none d-sm-block"
          style={{
            backgroundImage:
              "linear-gradient(349.66deg,#1f2025 4.11%,rgba(31,32,37,.3) 18.52%,rgba(31,32,37,0) 36.03%,rgba(31,32,37,0) 70.01%),linear-gradient(86deg,#000 4.11%,transparent 100.00%),url(" +
              Image +
              ")",
            height: "391px",
          }}
        >
          <div className="container">
            <div className="row">
              <div className="col-md-9 pt-5 mt-5">
                <h1 className="font-weight-bolder pb-2 sourcep">{Title}</h1>
              </div>
              <div className="col-12">
                <h2>
                  {SubTitle}
                </h2>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container faq pb-5">
        <div className="col-md-12">
          <div className="row">
            <div className="col-md-4 d-block d-sm-none mb-5 dropdown-filter">
              <DropdownButton
                id="dropdown-filter"
                title="Product Category"
                variant="secondary"
              >
                {list?.map((node, index) => {
                  return (
                    <>
                      {node.RelatedProductShowLink === true ?
                        <Dropdown.Item href={"/products/" + cTALinkParent + "/" + node.CTALink}>
                          {node.Title}
                        </Dropdown.Item>
                        : (
                          <Dropdown.Item>
                            {node.Title}
                          </Dropdown.Item>
                        )
                      }

                    </>
                  )
                })}
              </DropdownButton>
            </div>
            <div className="col-md-4 d-none d-sm-block">
              <div className="mb-4">
                <h6 className="font-weight-bold mb-0">{DetailTitle}</h6>
                {list?.map((node, index) => {
                  return (
                    <>
                      {node.RelatedProductShowLink === true ?
                        <>
                          <small>
                            <Link
                              className={
                                url.indexOf("/products/" + cTALinkParent + "/" + node.CTALink) > -1 ? "active-link" : "text-dark"
                              }
                              to={"/products/" + cTALinkParent + "/" + node.CTALink}
                            >
                              {node.Title}
                            </Link>
                          </small>
                          <br></br>
                        </>
                        : (
                          <>
                            <small>
                              <a
                                className="text-dark no-hover" style={{ textDecoration: "none" }}>
                                {node.Title}
                              </a>
                            </small>
                            <br></br>
                          </>
                        )
                      }
                    </>
                  )
                })}
              </div>
              <div className="">
                <h6 className="font-weight-bold">Resources</h6>
                <div className="d-flex flex-column">
                  <a class="font-weight-bold download-link" rel="noreferrer" href={file} target="_blank">
                    <img src={FileImage} alt={FileImage} className="shadow-sm mb-3" width={200} />
                  </a>
                  <a class="font-weight-bold download-link" rel="noreferrer" href={file} target="_blank">
                    {FileText}
                  </a>
                </div>
              </div>
            </div>
            <div className="col-md-8 pb-5">
              <div className="row">
                {ImageDescriptionShow === true ?
                  (
                    <>
                      <div className="col-xl-8 col-lg-8 col-md-12">
                        <div id="descviewer"></div>
                      </div>
                      <div className="col-xl-4 col-lg-4 col-md-12">
                         <img  src= {ImageDescriptionBody} alt={ImageDescriptionBody} className="shadow-sm mb-3 w-100" />
                      </div>
                    </>
                  ) : (
                    <div className="col-xl-12 col-lg-12 col-md-12">
                      <div id="descviewer"></div>
                    </div>
                  )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

